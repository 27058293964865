
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Image from 'next/image';
import { useRouter } from 'next/router';
import { Alert, Card, Head, LoadingOverlay, Typography } from '@electro/shared-ui-components';
import { Authorised, DemoModeWrapper } from '@electro/fleets/src/components';
import { useFleetsCurrentAdminAccountsLazyQuery } from '@electro/fleets/generated/graphql';
import { useFleetsAuth } from '@electro/fleets/src/hooks';
import * as Sentry from '@sentry/nextjs';
import useTranslation from 'next-translate/useTranslation';
import { LoginForm } from '@electro/fleets/src/components/LoginForm';
export default function BusinessLoginPage() {
    const { t } = useTranslation('common');
    const [getAdminAccounts] = useFleetsCurrentAdminAccountsLazyQuery();
    const { handleAccountSelect } = useFleetsAuth();
    const router = useRouter();
    const handleAuthorization = async () => {
        try {
            const { data } = await getAdminAccounts();
            // set account id on localStorage if user have only 1 account
            if (data?.fleetsCurrentAdminAccounts?.length === 1) {
                const firstAccount = data?.fleetsCurrentAdminAccounts?.[0];
                await handleAccountSelect(firstAccount);
            }
            // if the admin has more than one account we are
            // going to redirect him to account select page
            if (data?.fleetsCurrentAdminAccounts?.length > 1) {
                router.push('/dashboard/account-select');
            }
            else {
                router.push('/dashboard');
            }
        }
        catch (error) {
            Sentry.captureException(error);
            router.push('/dashboard/account-select');
        }
    };
    return (<Authorised onAuthorised={handleAuthorization}>
      {({ login, isLoginLoading, isAuthenticatedError }) => (<>
          <Authorised.LoggedOut>
            <div className="flex flex-col min-h-screen ">
              <Head title={t('login.page.heading')} description={t('login.page.heading')} showAppBanner={false}/>
              <main className="flex flex-col items-center justify-center flex-grow overflow-auto bg-base bg-gradient-mesh">
                <DemoModeWrapper show>
                  {({ isDemoMode }) => isDemoMode ? (<Card frosted className="lg:min-w-[550px] lg:w-[550px] min-w-[340px]">
                        <Typography variant="h2" as="h1">
                          {t('demo.logged_out_message')}
                        </Typography>
                        <Typography variant="body" as="p">
                          {t('errors.demo.login_page')}
                        </Typography>
                        {isAuthenticatedError?.message ? (<Alert variant="error">{isAuthenticatedError.message}</Alert>) : null}
                      </Card>) : null}
                </DemoModeWrapper>
                <DemoModeWrapper>
                  <Card frosted className="lg:min-w-[550px] lg:w-[550px] min-w-[340px]">
                    <div className="p-2 lg:p-8">
                      <LoginForm logo={<Image src="/images/electroverse-logo-dark-business.svg" alt={t('common.image_alt_text.logo')} width="240" height="240"/>} loading={isLoginLoading} onSubmit={({ email, password }) => login({ email, password })} error={isAuthenticatedError}>
                        <LoginForm.Fields />
                        <div className="pt-6">
                          <LoginForm.SubmitButton />
                        </div>
                        <LoginForm.ForgottenPasswordButton onClick={() => router.push(`/user/forgotten-password/`)}/>
                      </LoginForm>
                    </div>
                  </Card>
                </DemoModeWrapper>
              </main>
            </div>
          </Authorised.LoggedOut>

          <Authorised.Loading>
            <LoadingOverlay fixed/>
          </Authorised.Loading>
        </>)}
    </Authorised>);
}

    async function __Next_Translate__getStaticProps__1927712313e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/dashboard/login/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1927712313e__ as getStaticProps }
  